<template>
    <div class="projectData">
      <div class="ly-container__bg">
        <div class="ly_operation">
          <div class="left">
            <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
              >新增</el-button
            >
            <el-button @click="multiDelete">
              批量删除
              <i class="jr-iconjr-icon-delect" />
            </el-button>
            <div class="upload_time">
              <div class="caretTime" @click="uploadTime" style="color: #606266">
                按上传时间
              </div>
              <i
                class="el-icon-caret-top"
                :class="[
                  queryInfo.orderByParam == 's.create_time asc'
                    ? 'isActiveTime1'
                    : 'iconColor',
                ]"
                @click="caretTop"
              />
              <i
                class="el-icon-caret-bottom"
                :class="[
                  queryInfo.orderByParam == 's.create_time desc'
                  ? 'isActiveTime1'
                  : 'iconColor',
                ]"
                @click="caretBottom"
              />
            </div>
          </div>
          <el-input
            placeholder="请输入行为名称"
            v-model="queryInfo.queryConditions"
            class="lager-input"
            @change="search"
          >
            <template slot="append">
              <div @click="search">搜索</div>
            </template>
          </el-input>
        </div>
        <el-table
          class="ly_table"
          :data="projectTable"
          :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
          @selection-change="handleSelectionChange"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
       
          style="width: 100%"
        >
          <el-table-column type="selection" width="55" align="center" />
          <template slot="empty">
            <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
          </template>
          <el-table-column label="序号" align="center" width="80">
            <template v-slot="scope">
              <span
                >{{
                  (queryInfo.pageNum - 1) * queryInfo.pageSize +
                  (scope.$index + 1)
                }}
              </span>
            </template>
          </el-table-column>
  
          <el-table-column
            prop="processName"
            label="工艺名称"
            align="center"
            
          >
            <template v-slot="{ row }">
              <div @click="editItem(row)" style="cursor: pointer">
                <span>{{ row.processName }}</span>
              </div>
            </template>
          </el-table-column>
  
          <!-- <el-table-column
            prop="processDescribe"
            label="描述"
            align="center"
           
          >
          <template slot-scope="scope">
             <span>{{ scope.row.processDescribe }}</span>
            </template>
          </el-table-column> -->
          <el-table-column
          prop="sceneDescribe"
          label="描述"
          align="center"
          width="350"
        >
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.processDescribe"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.processDescribe !== null &&
                      scope.row.processDescribe !== ''
                    "
                  >
                    {{ scope.row.processDescribe.substring(0, 20) }}
                    <span v-if="scope.row.processDescribe.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
          <el-table-column
            prop="superiorBehaviorName"
            label="上级场景"
            align="center"
          
          >
            <template slot-scope="scope">
             <span>{{ scope.row.superiorBehaviorName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="label"
            label="所属行业"
            align="center"
          
          >
            <template slot-scope="scope">
             <span>{{ scope.row.label }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="industrialChainName"
            label="所属产业链"
            align="center"
          
          >
            <template slot-scope="scope">
             <span>{{ scope.row.industrialChainName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="label"
            label="产品名称"
            align="center"
          
          >
            <template slot-scope="scope">
             <span>{{ scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            width="120"
           
          ></el-table-column>
          <el-table-column label="操作" width="120" align="center">
            <template v-slot="{ row }">
              <button-table
                @buttonClick="editItem(row)"
                content="编辑"
                icon="jr-iconjr-icon-edit"
              />
              <button-table
                @buttonClick="deleteItem(row)"
                content="删除"
                icon="jr-iconjr-icon-delect"
              />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50, 100, 200]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </template>
    
    <script>
  import { pagination } from "@/mixins/pagination";
  import { selectGenerationProcessPage } from "@/api/Sceneknowledge";
  import { deleteById,  } from "@/api/standardKnowledge";
  
  import ButtonTable from "@/components/ButtonTable.vue";
  export default {
    name: "procedures",
  
    mixins: [pagination],
  
    components: {
      ButtonTable,
    },
  
    data() {
      return {
        // 查询
        queryInfo: {
          groupLabelLevel: 0,
          groupLabelType: "",
          orderByParam: "s.create_time asc",
          pageNum: 1,
          pageSize: 10,
          queryConditions: "",
        },
        total:0,
        projectTable: [], // 表格数据
      
        delete: {
          id: [],
          multiId: [],
        },
        dialogType: "行业类别",
        dialogVisible: false,
        editTableObj: {
          projectIndustryType: [], // 行业
          industryChildrenId: [], // 领域类别二级标签
          projectFieldType: [], // 领域
          fieldChildrenId: [], // 领域类别二级标签
        },
        valueI: 0,
      };
    },
    created() {
      this.search();
    },
  
    methods: {
      fileClick() {
        this.search();
      },
      _deleteProject(params) {
        
          
        this.$confirm("确认删除吗 ?", "提示")
          .then(async () => {
              
            const res = await deleteById(params);
  
            if (res.code === 200) {
              this.$message.success("删除成功");
              this.search();
            }
          })
          .catch(() => {});
      },
     
      multiDelete() {
        if (this.delete.multiId.length) {
          this._deleteProject(this.delete.multiId);
        }
      },
      handleSelectionChange(val) {
        this.delete.multiId = val.map((item) => {
          return item.id;
        });
       
      },
      editItem(row) {
          this.$router.push({
         
          path: "/knowledgeData/addprocedures",
          query:{
              id:row.id
          }
        });
        },
      async search() {
        const res = await selectGenerationProcessPage(this.queryInfo);
  
        if (res.code === 200) {
          this.projectTable = res.data.list;
          this.total=res.data.total
       
         
        }
  
        
      },
      searchCountry(val) {
        this.queryInfo.country = val;
        this.search();
      },
      searchProvince(val) {
        this.queryInfo.province = val;
        this.search();
      },
  
     
     
     
      async uploadTime() {
        switch (this.valueI) {
          case 0:
          //   this.queryInfo.columnName = "s.create_time";
          this.queryInfo.orderByParam = "s.create_time asc";
            this.valueI++;
            break;
          case 1:
          //   this.queryInfo.columnName = "s.create_time";
          this.queryInfo.orderByParam = "s.create_time desc";
         
            this.valueI++;
            break;
          case 2:
          this.queryInfo.orderByParam = "s.create_time asc";
          //   this.queryInfo.order = 1;
            this.valueI = 0;
            break;
        }
        this.search();
      },
     // 倒序
     caretBottom() {
     
      this.queryInfo.orderByParam = "s.create_time asc";
          this.search();
        },
          // 正序
          caretTop() {
              this.queryInfo.orderByParam = "s.create_time desc";
          this.search();
        },
  
  
      
      
    
  
      addItem() {
        this.$router.push({
          // name: "SceneknowledgeNormal",
          path: "/knowledgeData/addprocedures",
        });
      },
      addItemSpecial() {
        this.$router.push({
          name: "addprocedures",
        });
      },
  
      
      
     
     
      async deleteItem(row) {
        
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
          async () => {
            const res = await deleteById([ row.id ]);
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.search();
            } else {
              this.$$message.error("删除失败");
            }
          }
        );
      },
     
      sortChange(column) {
        this.pageNum = 1;
        if (column.prop === "dataIntegerity") {
          this.queryInfo.columnName = "data_integerity";
          if (column.order === "ascending") {
            this.queryInfo.order = 0;
          } else if (column.order === "descending") {
            this.queryInfo.order = 1;
          } else {
            this.queryInfo.order = 1;
            this.queryInfo.columnName = "update_time";
          }
        }
        this.search();
      },
    
   
    },
  };
  </script>
    <style lang="less" scoped>
  .projectData {
    /deep/ .el-input-group__append {
      background: #1e9fff;
      color: #fff;
      border: 1px solid #1e9fff;
    }
    .caretTime:hover {
      cursor: pointer;
    }
  }
  .isActiveTime1 {
    color: #1e9fff !important;
  }
  .iconColor {
    color: #c0c4cc !important;
  }
  /deep/ .filterInfo .el-form-item__content {
    padding: 0;
  }
  </style>
    